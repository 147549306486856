<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="POSinfo"
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5; font-size: 10em"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2 ml-2"
                      >Terminal Details
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="ml-5"
                      color="primary"
                      float-right
                      @click="addterminal"
                    >
                      <v-icon color="white">mdi-plus</v-icon>Add Terminal
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn text color="primary" @click="adduser(item)">
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    User
                  </v-btn>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-btn
                    text
                    small
                    ripple
                    color="red"
                    v-if="item.is_active"
                    :loading="loading2"
                    @click="action(item)"
                  >
                    Make inactive</v-btn
                  >
                  <v-btn
                    text
                    small
                    ripple
                    color="primary"
                    v-else
                    :loading="loading2"
                    @click="action(item)"
                  >
                    Make Active</v-btn
                  >
                </template>
              </v-data-table>
              <!-- Add Terminal dialog -->
              <v-dialog v-model="addterminaldialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Add Terminal</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="posform" v-model="valid_add_pos">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            outlined
                            dense
                            :rules="[(v) => !!v || 'Terminal Name is required']"
                            v-model="terminal_name"
                            required
                            label="Terminal Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="shop_name"
                            :items="allshop"
                            item-text="shop_name"
                            label="Shop Name*"
                            outlined
                            dense
                            :rules="[(v) => !!v || 'Shop Name is required']"
                            return-object
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="warehouse_name"
                            :items="allwarehouse"
                            item-text="Warehouse_name"
                            label="Warehouse Name"
                            outlined
                            dense
                            :disabled="shop_name !== 'shop_name'"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" @click="close">Cancel</v-btn>
                        <v-btn
                          color="success"
                          @click="save"
                          elevation="0"
                          :disabled="!valid_add_pos"
                          :loading="loading"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- Add User Dialog -->
    <v-dialog v-model="Adduserdialog" width="800px">
      <v-row style="width: 100%; background: #f5f5f5">
        <v-card class="ma-10" style="background: #f5f5f5">
          <v-col cols="12" style="background: #f5f5f5">
            <v-card elevation="0">
              <v-data-table
                :headers="headersUser"
                :items="USERinfo"
                sort-by="id"
                class="elevation-0"
                style="background: #f5f5f5; font-size: 10em"
              >
                <template v-slot:top>
                  <v-toolbar elevation="0" style="background: #f5f5f5">
                    <v-toolbar-title>User Details </v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>

                <template v-slot:item.statususer="{ item }">
                  <v-btn
                    text
                    small
                    ripple
                    color="red"
                    v-if="item.is_active"
                    @click="actionuser(item)"
                  >
                    Make inactive</v-btn
                  >
                  <v-btn
                    text
                    small
                    ripple
                    color="primary"
                    v-else
                    @click="actionuser(item)"
                  >
                    Make Active</v-btn
                  >
                </template>
              </v-data-table>

              <!-- Add User Form -->
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card>
              <v-card-title
                style="background: #f5f5f5"
                class="elevation-0 pb-3"
              >
                <span class="headline">Add User</span>
              </v-card-title>

              <v-card-text style="background: #f5f5f5">
                <v-form ref="userform" v-model="valid_add_user">
                  <v-card-text class="pt-12">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="email"
                          outlined
                          :rules="[(v) => !!v || 'Email is required']"
                          label="Email"
                          type="email"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="username"
                          outlined
                          label="User Name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="password"
                          required
                          v-validate="'required'"
                          dense
                          v-model="password"
                          :rules="[
                            () =>
                              password.length >= 6 ||
                              'Minimum 6 character is required',
                          ]"
                          outlined
                          label="Password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          type="password"
                          required
                          :rules="[
                            () =>
                              confirm_password == password ||
                              'Both password must be matched',
                          ]"
                          dense
                          v-model="confirm_password"
                          outlined
                          label="Confirm Password"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="phone_number"
                          outlined
                          label="Contact Number"
                          :rules="[(v) => !!v || 'Phone Number is required']"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-select
                          v-model="role"
                          :items="rolearray"
                          item-text="user_role"
                          label="User Role"
                          outlined
                          :rules="[(v) => !!v || 'Role is required']"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" @click="closeform">
                        Cancel
                      </v-btn>
                      <v-btn
                        color="#854FFF"
                        elevation="0"
                        :disabled="!valid_add_user"
                        @click="saveuser"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card>
      </v-row>
    </v-dialog>

    <v-snackbar v-model="snackbar" outlined centered :color="color">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    addterminaldialog: false,
    valid_add_pos: false,
    valid_add_user: false,
    confirm_password: "",
    snackbar: "",
    rolearray: ["PoS_Admin", "PoS_Operator", "PoS_Manager"],
    role: "",
    Adduserdialog: false,
    shop_name: {},
    terminal_name: "",
    allwarehouse: [],
    allshop: [],
    user: {},
    loading: false,
    loading2: false,
    text: "",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "terminal_name",
      },
      { text: "API KEY", value: "API_key" },
      // { text: "ID", value: "id" },
      //{ text: "Quantity", value: "quantity" },
      { text: "", value: "details", sortable: false },
      { text: "", value: "actions", sortable: false },
      { text: "Actions", value: "status", sortable: false },
      // { text: "", value: "data-table-expand" },
    ],
    headersUser: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Phone Number", value: "phone_number", sortable: false },
      { text: "Actions", value: "statususer", sortable: false },
      // { text: "", value: "data-table-expand" },
    ],
    shops: [],
    POSinfo: [],
    USERinfo: [],
    wareinfo: [],
    shopobject: {},
    email: "",
    phone_number: "",
    username: "",
    password: "",
    terminal_id: "",
  }),

  methods: {
    initialize() {
      axios
        .get(`productdetails/terminal_list/?merchant_id=${this.user.user_id}`)
        .then((response) => {
          if (response.data.success) {
            this.POSinfo = response.data.data;
          }
        });
    },
    getwharehouseandshop() {
      axios
        .get(`merchant/merchant_warehouse_shop_info/${this.user.user_id}/`)
        .then((response) => {
          this.wareinfo = response.data.warehouses;
          this.allshop = response.data.shops;
        });
    },
    addterminal() {
      this.addterminaldialog = true;
    },

    adduser(item) {
      this.Adduserdialog = true;
      this.USERinfo = item.users;
      this.terminal_id = item.id;
    },

    save(item) {
      let pos = {
        terminal_name: this.terminal_name,
        warehouse_id: "",
        shop_name: this.shop_name,
        shop_id: this.shop_name.id,
        admin_id: this.user.user_id,
      };
      this.loading = true;
      // This api will call after add Terminal information
      axios.post("productdetails/create_terminal/", pos).then((response) => {
        if (response.data.success) {
          this.POSinfo.push(response.data.data);
          this.close();
          this.text = response.data.message;
          this.loading = false;
          this.color = "success";
          this.snackbar = true;
          this.$refs.posform.reset();
        }
      });
    },
    saveuser(item) {
      let posUser = {
        email: this.email,
        password: this.password,
        phone_number: this.phone_number,
        username: this.username,
        id: this.terminal_id,
        role: this.role,
      };

      axios
        .post(`productdetails/create_pos_user/${this.terminal_id}/`, posUser)
        .then((response) => {
          if (response.data.success) {
            this.USERinfo.push(response.data.data);
            this.closeform();

            this.text = response.data.message;
            this.color = "success";
            this.snackbar = true;
            this.$refs.userform.reset();
          }
        });
    },
    actionuser(item) {
      const index = this.USERinfo.indexOf(item);
      axios
        .post(
          `productdetails/make_user_active_inactive/${item.id}/${this.terminal_id}/`
        )
        .then((response) => {
          this.USERinfo[index].is_active = response.data.is_active;
        });
    },
    action(item) {
      const index = this.POSinfo.indexOf(item);
      this.loading2 = true;
      axios
        .post(`productdetails/make_terminal_active_inactive/${item.id}/`)
        .then((response) => {
          this.POSinfo[index].is_active = response.data.is_active;
          this.loading2 = false;
          // setTimeout(() => {
          //   this.$router.go(this.$router.currentRoute);
          // }, 200);
        });
    },

    close() {
      this.addterminaldialog = false;
      this.addwarehouse = false;
      this.editwarehousedialog = false;
      this.addshoupialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeform() {
      this.$refs.userform.reset();
      this.Adduserdialog = false;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user && this.user.user_id) {
      this.initialize();
      this.getwharehouseandshop();
    }
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>